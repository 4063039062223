@tailwind base;
@tailwind components;
@tailwind utilities;


#root {
  white-space: pre-line;
}
#navbar {
  transition: 300ms;
}
.menu_nav {
  z-index: 1;
  position: relative;
}

.discount {
  position: relative;
  background: linear-gradient( #FFA6A6 , #FFA6A6 );
  background-repeat: no-repeat;
  background-position-x: 150px;
  display: inline-block;
  /* font-size: 22px; */
  line-height: 1.3;
    /* position: relative; */
  text-align: center;
  z-index: 1;
}
.discount::before {
  background: inherit;
  background-position: 0;
  content: "";
  height: 13px;
  left: 15px;
  opacity: .7;
  position: absolute;
  top: 9px;
  width: 100%;
  z-index: -1;
}


@media screen and (max-width: 575.98px) {
  .oculto  {
    display: none;
  }
  .discount::before {
    top: 18px;
  }
}

.sp-status {
  position: relative;
  display: inline-block; }
  .sp-status .sp-status-badge {
    width: .8em;
    height: .8em;
    margin-left: .3em;
    display: inline-block;
    border-radius: 50%; }
    .sp-status .sp-status-badge.sp-status-ok {
      background: #34c800; }
    .sp-status .sp-status-badge.sp-status-scheduled {
      background: #2498ec; }
    .sp-status .sp-status-badge.sp-status-minor {
      background: #fe8000; }
    .sp-status .sp-status-badge.sp-status-major {
      background: #ff231f; }

/* Tooltip text */
.sp-status .sp-tooltip {
  visibility: hidden;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  width: 130px; }
  .sp-status .sp-tooltip::after {
    content: " ";
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    color: black; }
  .sp-status .sp-tooltip--top, .sp-status .sp-tooltip--bottom {
    margin-left: -65px;
    left: 50%; }
  .sp-status .sp-tooltip--top {
    bottom: 120%; }
    .sp-status .sp-tooltip--top::after {
      top: 100%;
      /* At the bottom of the tooltip */
      left: 50%;
      border-color: black transparent transparent transparent; }
  .sp-status .sp-tooltip--bottom {
    top: 120%; }
    .sp-status .sp-tooltip--bottom::after {
      top: -10px;
      /* At the bottom of the tooltip */
      left: 50%;
      border-color: transparent transparent black transparent; }
  .sp-status .sp-tooltip--left, .sp-status .sp-tooltip--right {
    top: 50%;
    transform: translateY(-50%); }
    .sp-status .sp-tooltip--left::after, .sp-status .sp-tooltip--right::after {
      top: calc(50% - 5px);
      border-color: transparent transparent transparent black; }
  .sp-status .sp-tooltip--left {
    right: 105%; }
    .sp-status .sp-tooltip--left::after {
      left: calc(100% + 5px);
      border-color: transparent transparent transparent black; }
  .sp-status .sp-tooltip--right {
    left: 105%; }
    .sp-status .sp-tooltip--right::after {
      right: 100%;
      border-color: transparent black transparent transparent; }

/* Show the tooltip text when you mouse over the tooltip container */
.sp-status:hover .sp-tooltip {
  visibility: visible; }
